import {Injectable} from '@angular/core';
import {QuestionService} from './question.service';
import {HttpClient} from '@angular/common/http';
import {catchError, filter, map, retry} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Coaching} from '../models/coaching';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private data = [];

  constructor(private questionService: QuestionService, private http: HttpClient) {
  }

  setData(id, data) {
    this.data[id] = data;
  }

  getData(id) {
    return this.data[id];
  }

  getQuestion(url) {
    return this.http.get(url).pipe(map(response => response));
  }

  getCurrentQuestion(url) {
    return this.http.get(environment.apiQuestion + url).pipe(map(response => response));
  }

  monthlySummary() {
    return this.http.get(`${environment.api}weeklySummary`).pipe(map(response => response));
  }

  setAnswer(answer, questionID) {
    return this.http.post(`${environment.api}questionnaire/${questionID}/answer`, {
      value: answer
    }).pipe(map((response) => response));
  }

  getAnswer(questionID) {
    console.log('id in get answer');
    return this.http.get(`${environment.api}questionnaire/${questionID}/answer`).pipe(map((response) => response));
  }
  getDashboardScore() {
    return this.http.get(`${environment.api}score`).pipe(map((response: any) => {
     return response;
    }));
  }
  getScore() {
    return this.http.get(`${environment.api}score`).pipe(map((response: any) => {
      const responseData = [];
      let id = 0;
      response.scores.filter(score => {
        // if (Math.random() > 0 && Math.random() < 0.3) {
        //   task.category = 'fitness';
        // } else if (Math.random() >= 0.3 && Math.random() < 0.66) {
        //   task.category = 'health';
        // } else {
        //   task.category = 'wellness';
        // }
        score.id = ++id;
        responseData.push(score);
      });
      return responseData;
    }));
  }

  getEvaluation() {
    return this.http.get(`${environment.api}evaluation`).pipe(map((response) => response));
  }

  getBiologicalAge() {
    return this.http.get(`${environment.api}biologicalAge`).pipe(map((response) => response));
  }

  getWeeklySummary() {
    return this.http.get(`${environment.api}weeklySummary`).pipe(map((response) => response));
  }

  getWeeklyOverview() {
    return this.http.get(`${environment.api}weeklyOverview`).pipe(map((response) => response));
  }


  getCoaching() {
    return this.http.get<Coaching>(`${environment.api}coaching`).pipe(map((response: any) => {
      const responseData = [];
      let id = 0;
      response._embedded.exercises.filter(task => {
        // if (Math.random() > 0 && Math.random() < 0.3) {
        //   task.category = 'fitness';
        // } else if (Math.random() >= 0.3 && Math.random() < 0.66) {
        //   task.category = 'health';
        // } else {
        //   task.category = 'wellness';
        // }
        task.num = id++;
        task.icon = 'meditation';
        task.color = 'tertiary';
        task.textColor = 'light';
        task.buttonColor = '#2D313B';
        task.infoState = false;
        // task.name = 'Minullup verchic temo vellabo';
        responseData.push(task);
      });
      return responseData;
    }));
  }

  getCoachingId(task) {
    return this.http.get(task['_links'].next.href).pipe(map(response => response));
  }

  getStatistics() {
    return this.http.get(`${environment.api}statistics`).pipe(map((response: any) => response));
  }

  deleteUser(email) {
    return this.http.get(`${environment.api}deleteUser?email=${email}`).pipe(map((response: any) => response));
  }

  postExercise(id, completed) {
    return this.http.post(`${environment.api}coaching/${id}/log?completed=${completed}`, {}).pipe(map((response: any) => response));
  }

  register(data) {
    console.log('data: ', data);
    return this.http.put(`${environment.api}registration`, {
      password: data.password,
      nickname: data.nickname,
      token: data.token
    });
  }

  resetPassword(data) {
    return this.http.post(`${environment.api}resetPassword`, data).pipe(map(response => response));
  }

  requestPasswordReset(email) {
    const encodedEmail = `?email=${encodeURIComponent(email)}`;
    console.log(encodedEmail);
    return this.http.get(`${environment.api}resetPassword${encodedEmail}`,)
      .pipe(map((response) => response));
  }

  requestRegistration(data) {
    data.confirmationUrl = `https://${environment.name}.vikvato.de`;
    return this.http.post(`${environment.api}requestRegistration`, {
      email: data.email,
      membershipNumber: data.membershipNumber,
      confirmationUrl: data.confirmationUrl
    });
  }

  getAccountInfo() {
    return this.http.get(`${environment.api}profile`).pipe(map((response) => response));
  }

  updateProfileInfo(data) {
    return this.http.post(`${environment.api}profile`, {
      nickname: data.username,
      telNumber: data.mobileNumber,
      dailyReminder: data.dailyReminder
    }).pipe(map((response) => response));
  }

  //
  // getQuestions(id: number): Observable<Question> {
  //   // For now, assume that a hero with the specified `id` always exists.
  //   // Error handling will be added in the next step of the tutorial.
  //   const quesiton = QUESTIONS.find(h => h.id === id)!;
  //   this.questionService.add(`HeroService: fetched hero id=${id}`);
  //   return of(quesiton);
  // }
}

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Storage} from '@capacitor/storage';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  token;

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const token = localStorage.getItem('token');

      //Authentication by setting header with token value
      if (token) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Basic ${token}`
          }
        });
      }

      if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json'
        }
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.authenticationService.logout();
        return throwError(error);
      }));
  }
  //   // add authorization header with jwt token if available
  //   let currentAuthToken: any = Storage.get({key: 'my_token'}).then((value) => {
  //     currentAuthToken = value.value;
  //
  //   });
  //   if (currentAuthToken && currentAuthToken) {
  //     console.log(currentAuthToken);
  //     const headers = {
  //       Authorization: `Basic ${localStorage.getItem('token')}`,
  //     };
  //     if (request.responseType === 'json') {
  //       headers['Content-Type'] = 'application/json';
  //     }
  //     request = request.clone({
  //       setHeaders: headers
  //     });
  //   }
  //   return next.handle(request);
  // }

  //
  //
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   // Storage.get({key: 'my_token'}).then((result) => {
  //   //   this.token = result.value;
  //   //   console.log('token in interceptor: ', this.token);
  //   // });
  //   this.authenticationService.getToken().then((r) => {
  //     if (r.value) {
  //       console.log('token inside if', r.value);
  //       request = request.clone({
  //         setHeaders: {
  //           // eslint-disable-next-line @typescript-eslint/naming-convention
  //           Authorization: (`Basic ${r.value}`)
  //         }
  //       });
  //     } else {
  //       console.log('token in else in interceptor');
  //     }
  //   });
  //
  //   //Authentication by setting header with token value
  //   console.log('token before if', this.authenticationService.getToken());
  //   this.token = Storage.get({key: 'my_token'});
  //
  //
  //   if (!request.headers.has('Content-Type')) {
  //     request = request.clone({
  //       setHeaders: {
  //         'content-type': 'application/json'
  //       }
  //     });
  //   }
  //
  //   request = request.clone({
  //     headers: request.headers.set('Accept', 'application/json')
  //   });
  //
  //   return next.handle(request).pipe(
  //     map((event: HttpEvent<any>) => {
  //       if (event instanceof HttpResponse) {
  //         console.log('event--->>>', event);
  //       }
  //       return event;
  //     }),
  //     catchError((error: HttpErrorResponse) => {
  //       console.error(error);
  //       return throwError(error);
  //     }));
  // }


}

import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {IntroGuard} from './guards/intro.guard';
import {AutoLoginGuard} from './guards/auto-login.guard';
import {AuthGuard} from './guards/auth.guard';
import {DataResolverService} from './resolver/data-resolver.service';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginPageModule),
    canLoad: [IntroGuard, AutoLoginGuard] // Check if we should show the introduction or forward to inside
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroPageModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'questionnaire/:id',
    loadChildren: () => import('./pages/questionnaire/questionnaire.module').then(m => m.QuestionnairePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'request-password',
    loadChildren: () => import('./pages/authentication/request-password/request-password.module').then(m => m.RequestPasswordModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'register/:id',
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'resetPassword/:id',
    loadChildren: () => import('./pages/authentication/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'request-registration',
    loadChildren: () => import('./pages/authentication/request-registration/request-registration.module').then(m => m.RequestRegistrationPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'score',
    loadChildren: () => import('./pages/score/score.module').then(m => m.ScorePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reward',
    loadChildren: () => import('./pages/reward/reward.module').then(m => m.RewardPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

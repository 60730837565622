import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  questions: string[] = [];

  add(message: string) {
    this.questions.push(message);
  }

  clear() {
    this.questions = [];
  }
}

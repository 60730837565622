import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NavController, Platform,} from '@ionic/angular';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {RegisterPage} from './pages/authentication/register/register.page';
import {ResetPasswordPage} from './pages/authentication/reset-password/reset-password.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(
    private router: Router,
    protected platform: Platform,
    private deepLinks: Deeplinks) {
    this.initializeApp();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.deepLinks.route({'/register/:id': RegisterPage}).subscribe((match) => {
      this.router.navigateByUrl(match['$link'].path);
    }, (nomatch) => {
      console.warn('Unmatched route', nomatch);
    });
    this.deepLinks.route({'/resetPassword/:id': ResetPasswordPage}).subscribe((match) => {
      this.router.navigateByUrl(match['$link'].path);
    }, (nomatch) => {
      console.warn('Unmatched route', nomatch);
    });
  }

  ionViewDidEnter() {
    console.log('enter here');
  }


  initializeApp() {
    this.platform.resume.subscribe((res) => {
      console.log('resumed');
      this.deepLinks.route({'/register/:id': RegisterPage}).subscribe((match) => {
        this.router.navigateByUrl(match['$link'].path);
      }, (nomatch) => {
        console.warn('Unmatched route', nomatch);
      });
    });
    this.platform.ready().then(() => {
      this.deepLinks.route({'/register/:id': RegisterPage}).subscribe((match) => {
        this.router.navigateByUrl(match['$link'].path);
      }, (nomatch) => {
        console.warn('Unmatched route', nomatch);
      });
    });
    this.platform.resume.subscribe((res) => {
      console.log('resumed');
      this.deepLinks.route({'/resetPassword/:id': ResetPasswordPage}).subscribe((match) => {
        this.router.navigateByUrl(match['$link'].path);
      }, (nomatch) => {
        console.warn('Unmatched route', nomatch);
      });
    });
    this.platform.ready().then(() => {
      this.deepLinks.route({'/resetPassword/:id': ResetPasswordPage}).subscribe((match) => {
        this.router.navigateByUrl(match['$link'].path);
      }, (nomatch) => {
        console.warn('Unmatched route', nomatch);
      });
    });
  }

  // App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
  //   this.zone.run(() => {
  //     // Example url: https://beerswift.app/tabs/tab2
  //     // slug = /tabs/tab2
  //     const slug = event.url.split('.de').pop();
  //     if (slug) {
  //       this.router.navigateByUrl(slug);
  //     }
  //     // If no match, do nothing - let regular routing
  //     // logic take over
  //   });
  // });
}

import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../../services/data.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-registration',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit, AfterViewInit {

  credentials: FormGroup;
  token: '';
  terms = [
    {
      name: 'acceptTerms',
      text: 'Ich akzeptiere den ',
      styledText: 'Haftungsausschluss',
      value: false,
      color: '#41CEC2'
    },
    {
      name: 'confirmTerms',
      text: 'Ich habe die Datenschutzerklärung zur Kenntnis genommen und bin mit der Erhebung und Verarbeitung meiner pseudomisierten Daten einverstanden',
      styledText: '',
      value: false,
      color: '#ffffff'
    },
  ];

  constructor(private router: Router,
              private dataService: DataService,
              private fb: FormBuilder,
              private platform: Platform,
              private route: ActivatedRoute,
              private authService: AuthenticationService
  ) {

  }

  ngAfterViewInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      this.credentials.patchValue({token: params.params.id});
    });
  }

  ionViewDidEnter() {
    this.route.paramMap.subscribe((params: any) => {
      this.token = params.params.id;
      this.credentials.patchValue({token: params.params.id});
    });
  }

  ngOnInit() {
    this.platform.resume.subscribe(() => {
      this.route.paramMap.subscribe((params: any) => {
        this.credentials.patchValue({token: params.params.id});

      });
    });
    console.log('terms', this.terms);
    if (this.route.snapshot.params.token) {
      this.token = this.route.snapshot.params.token;
    }
    this.credentials = this.fb.group({
        nickname: ['', [Validators.required]],
        token: [this.token],
        acceptTerms: [false, Validators.requiredTrue],
        confirmTerms: [false, Validators.requiredTrue],
        password: ['', [Validators.required]],
        confirmPassword: new FormControl('', [
          Validators.required,
        ]),
      },
      {
        validators: (control) => {
          if (control.value.password !== control.value.confirmPassword) {
            control.get('confirmPassword').setErrors({notSame: true});
            // console.log(this.credentials);
            console.log('control: ', control);
          } else {
            // console.log('in else', this.credentials);

            // this.credentials.setErrors({
            //   error: true
            // });
          }
          return null;
        },
      });

  }

  register() {
    this.dataService.register(this.credentials.value).subscribe(response => {
      console.log('response in register: ', response);
      this.router.navigateByUrl(`/login`);
      // if (response) {
      //   await this.authService.convertUserData(this.credentials.value).then((res) => {
      //     console.log('res', res);
      //     this.authService.login({autologin: false}).subscribe(loginResponse => {
      //         console.log('response', loginResponse);
      //       }, error => {
      //         console.log('error');
      //         this.credentials.setErrors({
      //           error: true
      //         });
      //       }
      //     );
      //   }, (error) => {
      //     console.log('error is :');
      //   });
      // }
    });
  }

  get password() {
    return this.credentials.get('password');
  }

  get confirmPassword() {
    return this.credentials.get('confirmPassword');
  }

  get acceptTerms() {
    return this.credentials.get('acceptTerms');
  }

  get confirmTerms() {
    return this.credentials.get('confirmTerms');
  }

  get nickname() {
    return this.credentials.get('nickname');
  }
}

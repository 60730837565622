import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit, AfterViewInit {
  credentials: FormGroup;
  token;

  constructor(private activatedRoute: ActivatedRoute,
              private platform: Platform,
              private fb: FormBuilder,
              private dataService: DataService
  ) {
  }

  ngAfterViewInit(): void {
    this.activatedRoute.paramMap.subscribe((params: any) => {
      this.credentials.patchValue({token: params.params.id});
    });
  }

  ionViewDidEnter() {
    this.activatedRoute.paramMap.subscribe((params: any) => {
      this.token = params.params.id;
      this.credentials.patchValue({token: params.params.id});
    });
  }

  ngOnInit() {
    this.platform.resume.subscribe(() => {
      this.activatedRoute.paramMap.subscribe((params: any) => {
        this.credentials.patchValue({token: params.params.id});
        console.log('tokeeeen', this.token);
      });
    });
    if (this.activatedRoute.snapshot.params.token) {
      this.token = this.activatedRoute.snapshot.params.token;
    }
    this.credentials = this.fb.group({
        password: new FormControl('', [
          Validators.required,
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
        ]),
      },
      {
        validators: (control) => {
          if (control.value.password !== control.value.confirmPassword) {
            control.get('confirmPassword').setErrors({notSame: true});
            // control.get('password').setErrors({notSame: true});
            // console.log(this.credentials);
            console.log('control: ', control);
          } else {
            // console.log('in else', this.credentials);

            // this.credentials.setErrors({
            //   error: true
            // });
          }
          return null;
        },
      });

  }
  get password() {
    return this.credentials.get('password');
  }

  get confirmPassword() {
    return this.credentials.get('confirmPassword');
  }

  resetPassword() {
    const data = {
      token: this.token,
      password: this.credentials.value.password,
      confirmPassword: this.credentials.value.confirmPassword
    };
    this.dataService.resetPassword(data).subscribe(response => {
      console.log('reponse in reset password: ', response);
    });
  }
}

import { Injectable } from '@angular/core';
import {CanLoad, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(private authService: AuthenticationService, private router: Router) { }

  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        console.log('Found previous token, automatic login');
        if (isAuthenticated) {
          // this.router.navigateByUrl('/dashboard', { replaceUrl: true });

          // Directly open inside area
          // this.router.navigateByUrl('/dashboard', { replaceUrl: true });
          this.authService.login({autologin: true}).subscribe(response => {
            console.log('response', response);
          });
        } else {
          // Simply allow access to the login
          return true;
        }
      })
    );
  }
}

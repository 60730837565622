import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

import {Buffer} from 'buffer';
import {Storage} from '@capacitor/storage';
import {NavigationExtras, Router} from '@angular/router';
import {DataService} from './data.service';
import {environment} from '../../environments/environment';


const TOKEN_KEY = 'my_token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  constructor(private http: HttpClient, private router: Router, private dataService: DataService) {
    this.loadToken().then(() => {
    });
  }

  async getToken() {
    return await Storage.get({key: TOKEN_KEY});
  }

  async loadToken() {
    const token = await Storage.get({key: TOKEN_KEY});
    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  async storeData(type, data) {
    localStorage.setItem('token', data);
    await Storage.set({
      key: 'my_token',
      value: data
    });
  }

  async convertUserData(credentials: { email, password }) {
    const base = Buffer.from(`${credentials.email}:${credentials.password}`).toString('base64');
    this.storeData('token', base).then(() => {
    }).catch(error => console.log(error));
  }

  login(autologin) {
    return this.http.get(environment.api + `login`).pipe(map(
      (data: any) => {
        console.log('data: ', data);
        this.isAuthenticated.next(true);
        if (data.state === 'EXAMINATION') {
          return this.openDetailsWithState(data);
        } else {
          return this.router.navigateByUrl(`/dashboard`).then(() => {
            console.log('navigated to dashboard');
          }).catch(error => {
            return;
            console.log(error);
          });
        }
        tap(() => {
          this.isAuthenticated.next(true);
        });
      }));
  }

  openDetailsWithState(url) {
    const navigationExtras: NavigationExtras = {
      state: {}
    };
    const token = Storage.get({key: 'my_token'});
    // eslint-disable-next-line no-underscore-dangle
    this.dataService.getQuestion(url._links.questionnaire.href).subscribe((response: any) => {
      console.log('response in login getQuestion: ', response);
      navigationExtras.state.data = response;
      this.router.navigate([`questionnaire/${response.question.id}`], navigationExtras).then(() => {
      }).catch(error => {
        console.log(error);
      });
    });
  }


  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
    this.isAuthenticated.next(false);
    // window.location.reload();
    return Storage.remove({key: TOKEN_KEY});
  }
}
